import React, { useEffect } from "react"
import { graphql, navigate, PageProps } from "gatsby"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import LayoutContainer from "@containers/Layout"
import ContactUsFormContainer, {
  ContactInfoContainer,
  ContactInfoContext,
  ContactUsContext,
} from "@containers/ContactUs"
import SEO from "@components/SEO"
import { CAPTCHA_KEY } from "@helpers/constants"
import { ContactUsDataType } from "@typings/requests/partners/contact-us"
import "./contact-us.scss"

const ContactUsPage: React.FC<PageProps<ContactUsDataType>> = ({
  location,
  data,
}) => {
  useEffect(() => {
    navigate("/es")
  }, [])

  const params = new URLSearchParams(location.search).get("place") || ""
  const {
    allStrapiSignUpPage: {
      nodes: [contactUsData],
    },
  } = data
  return (
    <>
      <SEO
        title={contactUsData.title}
        lang={contactUsData.locale}
        description={contactUsData.description}
        image={contactUsData.image.localFile.publicURL}
        pathname={location.pathname}
      />
      <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_KEY}>
        <ContactUsContext place={params} data={contactUsData}>
          <ContactInfoContext>
            <LayoutContainer
              className="contact-us"
              lang="es"
              hideHeader
              isPartnerSubdomain
            >
              <div className="contact-us-layout">
                <div className="contact-us-layout__item">
                  <ContactInfoContainer />
                </div>
                <div className="contact-us-layout__item">
                  <ContactUsFormContainer />
                </div>
              </div>
            </LayoutContainer>
          </ContactInfoContext>
        </ContactUsContext>
      </GoogleReCaptchaProvider>
    </>
  )
}

export default ContactUsPage

export const query = graphql`
  {
    allStrapiSignUpPage(filter: { locale: { eq: "es" } }) {
      nodes {
        locale
        description
        title
        image {
          localFile {
            publicURL
          }
        }
        form {
          agreement {
            data {
              agreement
            }
          }
          button
          emailError
          email
          message
          name
          nameError
          phone
          phoneErrorExample
          phoneErrorText
          signupAlreadyInvitedSubtitle
          signupAlreadyInvitedTitle
          signupAlreadyRegisteredSignInButton
          signupAlreadyRegisteredSubtitle
          signupAlreadyRegisteredTitle
          signupAlreadyRegisteredTryAgainButton
          signupSuccessSubtitle
          signupSuccessTitle
        }
        info {
          title
        }
      }
    }
  }
`
